<template>
  <div>
    <v-card v-if="true || all_courses" outlined style='border-radius: 18px'>
      <v-card-title class='primary white--text py-3 px-5'>
        <v-col cols='12' class='pa-0 mb-2'>
          <div class="caption">
            <!-- {{ $dayjs.weekdays()[$dayjs().day()].toUpperCase() }} -->
            {{ $dayjs().format('dddd').toUpperCase() }}
          </div>
        </v-col>
        <v-col cols='12' class='pa-0'>
          <v-row class='px-3' justify='space-between'>
            <div class='title'>{{ $dayjs().format('DD [de] MMMM').toUpperCase() }}</div>
            <div class='headline font-weight-bold pr-8'>{{ $dayjs().format('YYYY')}}</div> 
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat >
                <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday" >
                  HOJE
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="prev" >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next" >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{calendar_month_title}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="mt-n4">
                  <card-loading-transparent :cardSize="30" :cardWidth="3" :cardText="false" v-if="loading"/>
                </div>
                <v-btn v-if="type=='day'" outlined class="mr-4" color="grey darken-2" @click="type = 'month'" >
                  Ver Mensal
                </v-btn>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar ref="calendar" color="primary" :type="type" v-model="focus"
                :events="coursesWithDateMapped" event-start="start_date" event-end="end_date"
                :event-color="getCourseColor"
                @click:event="showCourse"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
                :short-intervals="false"
              >
                <template v-slot:event="{ event }">
                  <div class="v-event-draggable">
                    <strong>{{ formatCourseTitle(event.name) }}</strong>
                    {{ formatCourseTime(event) }}
                  </div>
                </template>
              </v-calendar>
              <v-menu 
                offset-x v-model="selected_dialog"
                :close-on-content-click="false"
                :activator="selected_element"
                top
                left
              >
                <template>

                  <v-card style='border-radius: 18px;' v-if="Object.keys(this.selected_course).length != 0" elevation="3">
                    <v-card-title class="primary white--text" >
                      Detalhes do curso
                      <v-spacer></v-spacer>
                      <v-btn text class="ml-2" icon color="white" @click="selected_dialog = false" rounded><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>

                    <v-card-text :style="responsivity_vh">
                      <course-details short_description :selected_course="selected_course"/>
                    </v-card-text>

                    <v-divider/>
                    <v-card-actions >
                      <selected-course-card-actions :selected_course="selected_course"  @closeDialog="handleClose"></selected-course-card-actions>
                    </v-card-actions>
                  </v-card>
                </template>
                
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
      
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'CoursesCalendar',

    components: {
      SelectedCourseCardActions: () => import('../shared/SelectedCourseCardActions.vue'),
      CourseDetails: () => import('../../courses/CourseDetails.vue'),
    },
    
    props: {
      use_with_menu: {
        type: Boolean,
        default: false,
      },
      use_with_emit: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loading: false,
      focus: '',
      type: 'month',
      selected_course: {},
      selected_element: null,
      selected_dialog: false,
      courses: [],
    }),
    mounted () {
      this.getData()
      //this.$refs.calendar.checkChange()
    },
    methods: {
      handleClose(){
        this.selected_dialog = false
      },
      getData(start, end){
        this.loading = true;
        let filter = {}
        if(start && end){
          filter = {
            start_date: start,
            end_date: end,
          }
        }else{
          filter = {
            start_date: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            end_date: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
          }
        }
        this.indexCourses(filter).then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 600);
        });
      },
      viewDay (date) {
        this.focus = date.date
        this.type = 'day'
      },
      getCourseColor (course) {
        return course.color
      },
      setToday () {
        this.focus = ''
        this.getData()
      },
      prev () {
        this.$refs.calendar.prev()
        let start = this.$dayjs(this.$refs.calendar._data.lastStart.date).subtract(1, 'month').startOf('day').format('YYYY-MM-DD')
        let end = this.$dayjs(this.$refs.calendar._data.lastStart.date).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        this.getData(start, end)
      },
      next () {
        this.$refs.calendar.next()
        let start = this.$dayjs(this.$refs.calendar._data.lastStart.date).add(1, 'month').startOf('day').format('YYYY-MM-DD')
        let end = this.$dayjs(this.$refs.calendar._data.lastStart.date).add(1, 'month').endOf('month').format('YYYY-MM-DD')
        this.getData(start, end)
      },
      showCourse ({ nativeEvent, event }) {

        if(this.use_with_menu){
          const open = () => {
            this.selected_course = event
            this.selected_element = nativeEvent.target
            requestAnimationFrame(() => requestAnimationFrame(() => this.selected_dialog = true))
          }
          if (this.selected_dialog) {
            this.selected_dialog = false
            requestAnimationFrame(() => requestAnimationFrame(() => open()))
          } else {
            open()
          }
          nativeEvent.stopPropagation()
        }else if(this.use_with_emit){
          this.$emit('selectedCourse', event)
        }
      },
      updateRange ({start, end}) {
        // let filtered_courses = []
        // if(this.type == 'day'){
        //   filtered_courses = this.coursesWithDateMapped.filter( (el) => {
        //     return this.$dayjs(start.date).isBetween(this.$dayjs(el.start_date), this.$dayjs(el.end_date), 'day', [])
        //   })
        // }else if(this.type == 'month' && this.courses){
        //   // filtered_courses = this.coursesWithDateMapped.filter( (el) => {
        //   //   return this.$dayjs(start.date).isBetween(this.$dayjs(el.start_at), this.$dayjs(el.end_at), 'day', []) ||
        //   //   this.$dayjs(end.date).isBetween(this.$dayjs(el.start_at),
        //   //   this.$dayjs(el.end_at), 'day', []) ||
        //   //   this.$dayjs(el.start_at).isSameOrAfter(this.$dayjs(start.date)) ||
        //   //   this.$dayjs(el.end_at).isSameOrBefore(this.$dayjs(end.date)) 
        //   // })

        //   filtered_courses = this.coursesWithDateMapped
        // }
        this.courses = this.all_courses || []  
      },
      formatCourseTime({start_date, end_date}) {        
        return `${this.$dayjs(start_date).format('HH:mm')} - ${this.$dayjs(end_date).format('HH:mm')}`
      },
      formatCourseTitle(name){
        if(name && name.length > 12){
          return `${name.slice(0, 30) }...`
        }else{
          return name
        }
      },
      ...mapActions({
        indexCourses: 'Course/index'
      })
    },
    computed: {
      calendar_month_title(){
        if(this.$refs.calendar){
          return this.$refs.calendar.title
        }else{
          'erro'
        }
      },
      responsivity_vh(){
        return this.$vuetify.breakpoint.lg ? 
        'max-height: 70vh ; overflow-y: auto' : 
        this.$vuetify.breakpoint.smAndDown ? 
        'max-height: 60vh ; overflow-y: auto' : 
        'max-height: 50vh ; overflow-y: auto'
      },
      ...mapState({
        all_courses: state => state.Course.all_courses
      }),
      ...mapGetters({
        coursesWithDateMapped: "Course/coursesWithDateMapped",
      }),
    },
  }
</script>

<style lang="scss" scoped>

.v-menu__content {
  border-radius: 16px;
}

</style>